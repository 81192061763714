import { Link } from "@remix-run/react";
import { twJoin, twMerge } from "tailwind-merge";

type Stat = {
  label: string;
  value: string;
  unit?: string;
  since: string;
  linkLabel?: string;
  linkRoute?: string;
  bgColor?: string;
};
type Props = { data: Stat[]; className?: string };

export default function Stats(props: Props) {
  return (
    <section className="container py-32">
      <ul
        className={twMerge(
          "mx-auto w-max max-w-full",
          "grid auto-cols-max grid-flow-col",
          "max-lg:grid-rows-2 max-sm:grid-rows-4",
          "gap-20 md:gap-10",
          props.data.length === 2 && "max-lg:grid-rows-1 max-sm:grid-rows-2",
          props.className,
        )}
      >
        {props.data.map((s, idx) => (
          <li key={s.label} className="space-y-6 text-center">
            <div
              className={twJoin(
                "flex flex-col items-center justify-center",
                "h-52 w-52",
                "mx-auto rounded-full bg-gray-100 font-bold",
                "scale-90 select-none transition hover:scale-100",
                idx === 0 ? "text-3xl md:text-4xl" : "text-4xl md:text-6xl",
              )}
              style={{ backgroundColor: s.bgColor }}
            >
              <div>{s.value}</div>
              <div className="text-3xl">{s.unit}</div>
            </div>
            <div>
              <h4 className="text font-bold">{s.label}</h4>
              <h4>(Since {s.since})</h4>
              {s.linkRoute && (
                <Link className="mt-3 block text-sm underline" to={s.linkRoute}>
                  {s.linkLabel}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}
